import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import TitleUnderlined from '../../components/TitleUnderlined';
import convertHtml from '../../utils/convertHtml';
import TeamMember from '../meetTheTeam/teamMember';

const MeetTheTeamContainer = styled.div`
  background-color: #e1e1eb;
  width: 100%;
  padding-bottom: 100px;
  overflow: hidden;
`;

const HeaderContainer = styled.div``;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  margin-bottom: 30px;
`;

const Subtitle = styled.div`
  font-family: DM Sans;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #404040;
  margin-bottom: 150px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  @media (max-width: 715px) {
    font-size: 16px;
  }
`;

const TeamItemsContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TeamItemsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1065px;
  width: 100%;
  @media (max-width: 1065px) {
    max-width: 700px;
  }
  @media (max-width: 715px) {
    max-width: 350px;
  }
`;

export class MeetTheTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <MeetTheTeamContainer>
            <HeaderContainer>
              <Title>
                <TitleUnderlined
                  titleText={'<span>' + this.props.title.text + '</span>'}
                  underlineColor={this.props.block_color_theme}
                  textColor={'#404040'}
                  fontSize={'58px'}
                  h2Mode={true}
                  mobileFontSize={'35px'}
                />
              </Title>
              <Subtitle
                dangerouslySetInnerHTML={convertHtml(this.props.subtitle.text)}
              />
            </HeaderContainer>
            <TeamItemsContainerOuter>
              <TeamItemsContainer>
                {this.props.items.map((item, index) => (
                  <TeamMember data={item} id={index} />
                ))}
              </TeamItemsContainer>
            </TeamItemsContainerOuter>
          </MeetTheTeamContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default MeetTheTeam;
