import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ReviewSliderContainer = styled.div`
  padding-top: 87px;
  padding-bottom: 80px;
  background: #4758eb;

  @media (max-width: 1366px) {
    padding-top: 87px;
    padding-bottom: 80px;
  }
  @media (max-width: 1199px) {
    padding-top: 60px;
    padding-bottom: 55px;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 20px;
  }
  @media (max-width: 480px) {
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 688px);
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1366px) {
    width: calc(100% - 520px);
  }
  @media (max-width: 1199px) {
    width: calc(100% - 440px);
  }
  @media (max-width: 1024px) {
    width: calc(100% - 300px);
  }
  @media (max-width: 767px) {
    width: calc(100% - 220px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;
export const TopSectionLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    max-width: 70px;
    margin-right: 10px;
  }
  @media (min-width: 481px) and (max-width: 575px) {
    img {
      margin: 15px 10px 15px 0;
      max-width: 50px;
    }
  }
`;
export const TopSectionRight = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    color: #fff;
  }

  svg {
    margin-left: 11px;
    fill: #fff !important;
  }
  @media (min-width: 481px) and (max-width: 575px) {
    svg {
      margin-left: 5px;
    }
  }
`;
export const GoogleDiv = styled(motion.img)`
  max-width: 163px;
  width: 100%;
  margin: 48px auto;

  @media (max-width: 1199px) {
    margin: 36px auto;
  }
  @media (max-width: 965px) {
    max-width: 130px;
    margin: 27px auto;
  }
  @media (max-width: 735px) {
    max-width: 100px;
    margin: 20px auto;
  }
`;
export const RecoLink = styled.div`
  @media (min-width: 481px) and (max-width: 575px) {
    font-size: 14px;
  }
`;
