import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import LazyImage from '../components/lazy-image';
import Squiggle from '../components/squiggle';

const SocialImagesBlockContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const SocialImagesBlockContainer = styled.div`
  width: 90%;
  background-color: #e1e1eb;
  position: relative;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 70px;

  span {
    color: #404040;
  }

  span {
    background: unset !important;
  }
`;

const Subtitle = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #404040;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 70px;
`;

const ImageGridContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 70px;
`;

const ImageGrid = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  max-width: 1210px;
  z-index: 3;
  @media (max-width: 1357px) {
    max-width: 910px;
  }
  @media (max-width: 1021px) {
    max-width: 610px;
  }
  @media (max-width: 900px) {
    max-width: 510px;
  }
  @media (max-width: 570px) {
    max-width: 340px;
  }
  @media (max-width: 450px) {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ImageItem = styled(motion.div)`
  width: 232px;
  height: 232px;
  margin: 35px;
  @media (max-width: 900px) {
    width: 160px;
    height: 160px;
    margin: 5px;
  }
  @media (max-width: 450px) {
    width: 80px;
    height: 80px;
    margin: 5px;
  }
`;

const OddImageItem = styled(motion.div)`
  width: 232px;
  height: 232px;
  margin: 35px;
  padding-top: 30px;
  @media (max-width: 1357px) {
    padding-top: 0px;
  }
  @media (max-width: 900px) {
    width: 160px;
    height: 160px;
    margin: 5px;
  }
  @media (max-width: 450px) {
    width: 80px;
    height: 80px;
    margin: 5px;
    padding: 0px;
  }
`;

const RightSquiggle = styled.div`
  position: absolute;
  top: -90px;
  right: -100px;
  z-index: 2;
  transform: rotate(45deg) scale(0.8);
  @media (max-width: 750px) {
    display: none;
  }
`;

const LeftSquiggle = styled.div`
  position: absolute;
  bottom: 0;
  left: -130px;
  transform: rotate(-15deg) scale(-0.8);
  @media (max-width: 750px) {
    display: none;
  }
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const imageOddAnim = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const imageEvenAnim = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class SocialImagesBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <SocialImagesBlockContainerOuter>
            <SocialImagesBlockContainer>
              <TitleContainer>
                <TitleUnderlined
                  titleText={'<span>' + this.props.title.text + '</span>'}
                  underlineColor={this.props.block_color_theme}
                  textColor={'#404040'}
                  fontSize={'58px'}
                  mobileFontSize={'45px'}
                />
              </TitleContainer>
              <Subtitle
                dangerouslySetInnerHTML={convertHtml(this.props.subtitle.text)}
              />
              <ImageGridContainer>
                <ImageGrid
                  variants={container}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                >
                  {this.props.items.map((item, index) =>
                    index % 2 === 0 ? (
                      <ImageItem key={index} variants={imageEvenAnim}>
                        {item.image && <LazyImage {...item.image} />}
                      </ImageItem>
                    ) : (
                      <OddImageItem key={index} variants={imageOddAnim}>
                        {item.image && <LazyImage {...item.image} />}
                      </OddImageItem>
                    )
                  )}
                </ImageGrid>
              </ImageGridContainer>
            </SocialImagesBlockContainer>
          </SocialImagesBlockContainerOuter>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default SocialImagesBlock;
