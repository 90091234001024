import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const FormWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -18px;
  margin-left: -18px;
  margin-top: 3em;
  font-family: DM Sans;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  @media (max-width: 965px) {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 1em;
  }
  font-family: DM Sans;
`;
export const FormGroup = styled.div`
  position: relative;
  label {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    z-index: 2;
  }
  .inputError {
    border-color: #ff000a !important;
  }
  span.error {
    position: relative;
    color: #ff000a;
    margin-top: 9px;
    font-weight: 500;
    font-size: 18px;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    display: block;
    @media (max-width: 965px) {
      position: relative;
      top: 5px;
      display: block;
      padding: 4px 0 13px;
    }
  }

  input,
  textarea,
  select {
    color: #404040;
    border-width: 0;
    z-index: 5;
    position: relative;

    &:focus {
      outline: 0;
      box-shadow: 0;
    }

    &::placeholder {
      color: rgba(44, 62, 80, 0.5);
    }
  }

  label {
    font: inherit;
    line-height: 1;
    display: block;
    width: 100%;
    margin-left: 10px;
    span {
      color: #4758eb;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
    }
  }
  textarea {
    height: 298px;

    @media (max-width: 965px) {
      height: 150px;
    }
  }
  input,
  select,
  textarea {
    font: inherit;
    line-height: 1;
    display: block;
    width: 100%;
    padding: 23px;
    transition: border-color 0.25s ease-in-out;
    color: #404040;
    border: 2px solid #404040;
    background-color: transparent;
    box-sizing: border-box;
    font-size: 16px;

    @media (max-width: 965px) {
      padding: 10px;
      border-width: 1px;
    }

    + label {
      position: absolute;
      top: 12px;
      left: 12px;
      display: inline-block;
      width: auto;
      margin: 0;
      padding: 12px;
      transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
      transform-origin: 0 0;
      color: #404040;

      font-size: 16px;
      font-weight: bold;
      @media (max-width: 965px) {
        font-size: 14px;
        top: -6px;
        left: 2px;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      border-color: #404040;
    }
    &:focus + label,
    &:not(:placeholder-shown) + label {
      z-index: 2500;
      padding: 10.5px;
      transform: translate(0, -2em) scale(0.9);
      color: #aaaaaa;
      font-weight: 600;
      font-size: 14px;
      background: #e1e1eb;
      span {
        opacity: 0;
      }
      @media (max-width: 965px) {
        padding: 8.5px;
        transform: translate(0, -1.2em) scale(0.9);
      }
    }

    &::placeholder {
      color: transparent;
    }
  }

  select {
    background: url('../..') no-repeat right transparent;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 24px) calc(1em + 15px),
      calc(100% - 15px) calc(1em + 15px);
    background-size: 11px 8px, 11px 8px;
    background-repeat: no-repeat;
    line-height: 1.5;

    @media screen and (max-width: 63.9375em) {
      background-position: calc(100% - 24px) calc(1em + 3px),
        calc(100% - 15px) calc(1em + 3px);
    }
  }

  @media (max-width: 965px) {
  }
  @media (max-width: 735px) {
  }
`;

export const HalfCol = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 18px;
  box-sizing: border-box;

  @media (max-width: 965px) {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding: 8px 15px;
  }

  @media (max-width: 735px) {
  }
`;

export const FullCol = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 18px;
  box-sizing: border-box;
  &.right {
    justify-content: flex-end;
    display: flex;
  }

  @media (max-width: 965px) {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding: 8px 15px;
  }
  @media (max-width: 735px) {
  }
`;

export const ErrorUI = styled.div`
  text-align: center;
  display: block;
  width: 100%;
  color: ${props => (props.isSuccess ? '#74bb74' : '#ff000a')};
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 12px;
`;
export const ContactFormContainer = styled.div``;
export const GetInTouchSection = styled.div``;
export const AlternateEmailSection = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #404040;
  &.dark {
    color: #404040;
    strong {
      color: #4758eb;
      font-weight: 900;
    }
  }
  strong {
    color: #4758eb;
  }
  @media (max-width: 965px) {
    font-size: 19px;
    text-align: center;
    margin-top: 1rem;
    br {
      display: none;
    }
  }
  @media (max-width: 735px) {
    font-size: 18px;
    text-align: left;
  }
`;
export const SectionContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: auto;
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    padding: 0px 25px;
  }
  @media (max-width: 965px) {
    padding: 15px;
  }
  @media (max-width: 735px) {
    padding: 15px;
  }
`;
