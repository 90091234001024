import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import convertHtml from '../utils/convertHtml';
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import ButtonUnderlinedWArrow from '../components/ButtonUnderlinedWArrow';

const DynamicCaseStudy = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 500px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const ParagraphTitle = styled.h2`
  font-weight: 800;
  font-size: 40px;
  color: #404040;
  font-weight: 400;
  font-style: normal;
  font-family: DM Serif Text;
  @media (max-width: 800px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const ParagraphText = styled.div`
  color: #404040;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.01em;

  ul {
    list-style: outside;
    padding-left: 20px;
    list-style-type: square;
  }

  strong {
    font-weight: bold;
  }
`;

const DynamicCaseStudyLeft = styled.div`
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #e1e1eb;

  @media only screen and (max-width: 50em) {
    width: 100%;
    padding: 20px 30px;
  }

  h2,
  p {
    color: #404040;
  }
`;

const ParagraphTextWrapper = styled.div`
  div {
    color: #404040;
  }
`;

const DynamicCaseStudyLeftInner = styled.div`
  padding-right: 70px;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: 50em) {
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 70px;
  }
`;

const DynamicCaseStudyRight = styled.div`
  width: calc(50% + 50px);
  margin-left: -50px;
  padding-top: 30px;
  padding-bottom: 30px;

  @media only screen and (max-width: 50em) {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-top: -70px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export class DynamicCaseStudyOne extends Component {
  render() {
    return (
      <DynamicCaseStudy>
        <DynamicCaseStudyLeft>
          <DynamicCaseStudyLeftInner>
            {this.props.label_title && (
              <Label
                text={this.props.label_title.text}
                color={this.props.label_colour}
              />
            )}
            <BlankSpace height="30px" />
            <ParagraphTitle>{this.props.title_text.text}</ParagraphTitle>
            <BlankSpace height="30px" />
            <ParagraphTextWrapper>
              <ParagraphText>
                <div
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_text.html
                  )}
                />
              </ParagraphText>
            </ParagraphTextWrapper>
            {this.props.cta_link.url && (
              <>
                <BlankSpace height="30px" />
                <ButtonUnderlinedWArrow
                  buttonText={this.props.cta_text.text}
                  link={this.props.cta_link.url}
                  color={this.props.cta_color}
                  arrowcolor={this.props.cta_arrow_color}
                  target={this.props.cta_link_target}
                />
              </>
            )}
          </DynamicCaseStudyLeftInner>
        </DynamicCaseStudyLeft>
        <DynamicCaseStudyRight>
          {this.props.right_aligned_video &&
          this.props.right_aligned_video.url &&
          this.props.right_aligned_video.url.includes('.mp4') ? (
            <video autoPlay loop playsInline muted>
              <source
                src={this.props.right_aligned_video.url}
                type="video/mp4"
              />
            </video>
          ) : (
            <img src={this.props.right_aligned_image.url} alt="Image" />
          )}
        </DynamicCaseStudyRight>
      </DynamicCaseStudy>
    );
  }
}

export default DynamicCaseStudyOne;
